import {Redirect} from 'react-router-dom';
import type { MenuProps } from 'antd';
import React, { Key, lazy } from 'react';
export interface routesDTO {
    path:string,
    exact?:boolean,
    component?:React.LazyExoticComponent<()=>JSX.Element> | (()=>JSX.Element),
    title?:string,
    label?:string
    children?:{key:Key,label:string}[],
    hide?:boolean
}
const routes:routesDTO[] = [
    {
        path:'/home',
        component:lazy(()=>import('../pages/home/index')),
        title: '网站首页',
        label:'Home page',
    },
    {
        path:'/about', //关于我们 About us
        component:lazy(()=>import('../pages/aboutUs/index')),
        title: '关于我们',
        label:'About us',
    },
    {
        path:'/product', //产品概览 Product overview
        component:lazy(()=>import('../pages/productOverview/index')),
        title: '项目案例',
        label:'Product overview',
    },
    {
        path:'/productDetails', //产品概览 Product overview
        component:lazy(()=>import('../pages/productDetails/index')),
        title: '产品细节',
        label:'detail',
        hide:true,

    },
    
    // {
    //     path:'/contact',
    //     component:lazy(()=>import('../pages/contactUs/index')),
    //     title: '联系我们',
    //     label:'Contact us',
    // },
    // {
    //     path:'/cooperation', //关于我们 About us
    //     component:lazy(()=>import('../pages/cooperation/index')),
    //     title: '合作须知',
    //     label:'Cooperation',
    // },
   
    {
        path:'/',
        title: '网站首页',
        exact:true,
        component:()=><Redirect to='/home'/>
    },
];

export default routes;