import React, { useState } from 'react';
import { Layout,BackTop,Image, } from 'antd';
import ScrollToTop from './components/scrollTop'
import { Redirect, Route,Switch} from 'react-router-dom';
import routes from './routes';
import './App.less';
import Header from './components/header';
import Footer from './components/footer';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
const backTopIco = 'https://cdn.szconst.com/static/clmbo/backTopIco.svg'
const { Content } = Layout;


function App() {
  return (
    <Layout className='app'>
      <Header/>
      <Content className='container'>
      {/* <FloatButton tooltip={<div>Documents</div>} /> */}
      <ScrollToTop>
        <Switch>
          <React.Suspense >
          {
            routes.map((route,index)=>{
              const { path, component,exact} = route;
              return (
                <Route
                  exact={exact}
                  key={index}
                  path={path}
                  component={component}
                /> 
              )
            })
          }
          </React.Suspense>
        </Switch>
        </ScrollToTop>
      </Content>
      <Footer/>
      <BackTop>
        <Image src={backTopIco} style={{width:40,height:40}} preview={false}/>
      </BackTop>
    </Layout>
  );
}

export default App;
