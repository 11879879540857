import React from 'react';
import { Layout,Image, } from 'antd';
import './index.less';


const Footer  = ()=>{

    const footerData = [
        {
            id:'1',
            title:'联系电话（微信同号）',
            details:'15013715560',
        },
        {
            id:'2',
            title:'商务合作',
            details:'heancheng@126.com',
        },
        {
            id:'3',
            title:'微信',
            details:'Yky77221',
        },
    ]
    
    return (
        <Layout.Footer className='app-footer'>
            <div id='footer-box' className='app-footer-box'>

                <div className='app-footer-box-mode-1'>
                    <div className='app-footer-box-mode-1-left'>
                        {
                            footerData.map(item=>(
                                <div className='app-footer-box-mode-1-left-item' key={item.id}>
                                    <div>
                                        {item.title}
                                    </div>
                                    <div>
                                        {item.details}
                                    </div>
                                </div> 
                            ))
                        }
                    </div>

                    <div className='app-footer-box-mode-1-right'>
                    在线咨询
                    </div>

                </div>

                <div className='app-footer-box-mode-2'>
                    <div className='app-footer-box-mode-2-left'>
                        版权所有 © 2024 深圳市常量科技有限公司 保留所有权利。
                    </div>
                    <div className='app-footer-box-mode-2-right'>
                        <span><a className='bottom-authority-href' href="http://beian.miit.gov.cn/" target="_blank"
                rel="noreferrer">粤ICP备2023084633号-1</a></span>
                        <span>深圳市宝安区坪洲前城中心号</span>
                    </div>
                </div>
                
            {/* <div className=''>
                
            </div>
            <p>
            Copyright @ 2024 深圳市常量科技有限公司 Allrights reserved. 
            备案号:
                <a className='bottom-authority-href' href="http://beian.miit.gov.cn/" target="_blank"
                rel="noreferrer">粤ICP备2023084633号-1
                </a>
            </p> */}
            </div>
            
        </Layout.Footer>
    )
}

export default Footer;